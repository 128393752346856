/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContentSection from '../../../components/ContentSection';
import MemberListing from '../../../components/MemberListing';
// ASSETS - AGENTS LISTING PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
import AgentImagePlaceholder from '../../../assets/img/agent-member_placeholder.png';

const AgentsListingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    getMembers();
  }, []);

  const getMembers = () => {
    setIsLoading(true);
    const agentsData = [
      {
        name: 'Allison Bourgoyne',
        emailAddress: 'allisonbourg@belisouth.net',
        phoneNumber: '(225)287-5523',
        licenseNumber: '99562396',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_allison-bourgoyne.png`,
      },
      {
        name: 'Anitalyn Ferguson',
        emailAddress: '2anita@cox.net',
        phoneNumber: '(225)715-1500',
        licenseNumber: '69109',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_anitalyn-ferguson.png`,
      },
      {
        name: 'Ashley Jeansonne',
        emailAddress: 'ashley_jeansonne@yahoo.com',
        phoneNumber: '(225)281-4599',
        licenseNumber: '15284',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_ashley-jeansonne.png`,
      },
      {
        name: 'Barbara Coxe',
        emailAddress: 'barbaracoxe@yahoo.com',
        phoneNumber: '(225)921-6823',
        licenseNumber: '47628',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_barbara-coxe.png`,
      },
      {
        name: 'Briauna Cox-Granger',
        emailAddress: 'coxbriauna@gmail.com',
        phoneNumber: '(225)278-7977',
        licenseNumber: '77864',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_briauna-cox.png`,
      },
      {
        name: 'Bruce Easterly',
        emailAddress: 'bruceweasterly@gmail.com',
        phoneNumber: '(225)278-7700',
        licenseNumber: '43405',
        title: 'Associate Broker',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_bruce-easterly.png`,
      },
      {
        name: 'Charlotte Lockhart',
        emailAddress: 'charlottecoxelockhart@gmail.com',
        phoneNumber: '(225)937-8741',
        licenseNumber: '26639',
        title: 'Associate Broker',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_charlotte-lockhart.png`,
      },
      {
        name: 'Chris Colombo',
        emailAddress: 'ccolombo50@gmail.com',
        phoneNumber: '(504)439-1950',
        licenseNumber: '48743',
        title: 'Associate Broker',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_chris-colombo.png`,
      },
      {
        name: 'Christine Tillman',
        emailAddress: 'tillman@mandybentonrealty.com',
        phoneNumber: '(225)276-1955',
        licenseNumber: '995694038',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_lois-christine-tillmann.png`,
      },
      {
        name: 'Dale Erdey',
        emailAddress: 'dale@erdy.net',
        phoneNumber: '(225)938-7406',
        licenseNumber: '42753',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_dale-erdey.png`,
      },
      {
        name: 'Darryl Dyess',
        emailAddress: 'dgd7540@yahoo.com',
        phoneNumber: '(225)572-9880',
        licenseNumber: '43890',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_darryl-dyess.png`,
      },
      {
        name: 'Dawn Sonnier',
        emailAddress: 'gameofhomesla@gmail.com',
        phoneNumber: '(225)572-6187',
        licenseNumber: '995699442',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_dawn-sonnier.png`,
      },
      // {
      //   name: 'Diane Milton',
      //   emailAddress: 'dianesmilton@yahoo.com',
      //   phoneNumber: '(225)337-3017',
      //   licenseNumber: '912123225',
      //   title: 'Realtor',
      //   link: '',
      //   linkTitle: '',
      //   image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_diane-milton.png`,
      // },
      {
        name: 'Elizabeth Butler',
        emailAddress: 'elizabethannelockhart@gmail.com',
        phoneNumber: '(225)454-2617',
        licenseNumber: '995704380',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_elizabeth-butler.png`,
      },
      {
        name: 'Heather Corsentino',
        emailAddress: 'heather.corsentino@gmail.com',
        phoneNumber: '(225)279-0605',
        licenseNumber: '69532',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_heather-corsentino.png`,
      },
      {
        name: 'Jade Coxe',
        emailAddress: 'jadecoxe7@gmail.com',
        phoneNumber: '(225)454-2623',
        licenseNumber: '995702437',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_jade-coxe.png`,
      },
      {
        name: 'Jeff Barker',
        emailAddress: 'barkerhomesales@aol.com',
        phoneNumber: '(225)369-1132',
        licenseNumber: '995683067',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_jeff-barker.png`,
      },
      {
        name: 'Jill Vaughn',
        emailAddress: 'jillvaughn@mandybentonrealtygroup.com',
        phoneNumber: '(225)921-3774',
        licenseNumber: '4375',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_jill-vaughn.png`,
      },
      {
        name: 'Joel Thames',
        emailAddress: 'realtorjoelthames@gmail.com',
        phoneNumber: '(225)999-5635',
        licenseNumber: '995698620',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_joel-thames.png`,
      },
      {
        name: 'Judith Nesom',
        emailAddress: 'judithnesom@yahoo.com',
        phoneNumber: '(225)405-9765',
        licenseNumber: '19669',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_judith-nesom.png`,
      },
      {
        name: 'Katie Thompson',
        emailAddress: 'kbenton18@cox.net',
        phoneNumber: '(225)953-0677',
        licenseNumber: '995683141',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_katie-thompson.png`,
      },
      {
        name: 'Lane Doiron',
        emailAddress: 'lanesdoiron@gmail.com',
        phoneNumber: '(225)939-6819',
        licenseNumber: '995690368',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_lane-doiron.png`,
      },
      {
        name: 'Laura Cotten',
        emailAddress: 'Icotten2015@gmail.com',
        phoneNumber: '(225)202-0453',
        licenseNumber: '995697557',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_laura-cotten.png`,
      },
      {
        name: 'Linda Day',
        emailAddress: 'Iday@mandybentonrealtygroup.com',
        phoneNumber: '(225)938-6374',
        licenseNumber: '99562816',
        title: 'Associate Broker',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_linda-day.png`,
      },
      {
        name: 'Lisa Broyles',
        emailAddress: 'lisal1141@yahoo.com',
        phoneNumber: '(225)603-0546',
        licenseNumber: '995681841',
        title: 'Associate Broker',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_lisa-broyles.png`,
      },
      {
        name: 'Lisa Michaelson',
        emailAddress: 'homesbylisam@gmail.com',
        phoneNumber: '(225)324-7599',
        licenseNumber: '995684314',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_lisa-michaelson.png`,
      },
      {
        name: 'Mandy Benton',
        emailAddress: 'mbenton@mandybentonrealty.com',
        phoneNumber: '(225)335-0743',
        licenseNumber: '55736',
        title: 'Broker',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_mandy-benton.png`,
      },
      {
        name: 'Mary Ann Allen',
        emailAddress: 'maallen73@yahoo.com',
        phoneNumber: '(225)955-0143',
        licenseNumber: '59203',
        title: 'Associate Broker',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_mary-ann.png`,
      },
      {
        name: 'Raegan Furrow',
        emailAddress: 'raegan1984@gmail.com',
        phoneNumber: '(225)939-6594',
        licenseNumber: '1995684214',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_raegan-furrow.png`,
      },
      {
        name: 'Sara Easterly',
        emailAddress: 'sseasterly@gmail.com',
        phoneNumber: '(225)933-2099',
        licenseNumber: '43413',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_sara-easterly.png`,
      },
      {
        name: 'Sarah Pollard',
        emailAddress: 'sarah.w.pollard@gmail.com',
        phoneNumber: '(225)333-1466',
        licenseNumber: '995708566',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_sarah-pollard.png`,
      },
      {
        name: 'Scott Prescott',
        emailAddress: 'aci2006@aol.com',
        phoneNumber: '(225)933-3341',
        licenseNumber: '995698632',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_scott-prescott.png`,
      },
      {
        name: 'Shane Marler',
        emailAddress: 'marlerconstruction@hotmail.com',
        phoneNumber: '(225)268-5153',
        licenseNumber: '995689911',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_shane-marler.png`,
      },
      // {
      //   name: 'Sharyl Rushing',
      //   emailAddress: 'sharylrushing@gmail.com',
      //   phoneNumber: '(225)937-7748',
      //   licenseNumber: '995695800',
      //   title: 'Realtor',
      //   link: '',
      //   linkTitle: '',
      //   image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_sharyl-rushing.png`,
      // },
      {
        name: 'Shawn Wilson',
        emailAddress: 'shawn@house2homesteamcom',
        phoneNumber: '',
        licenseNumber: '99563905',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_shawn-wilson.png`,
      },
      // {
      //   name: 'Sheri Luce',
      //   emailAddress: 'sheri@house2homesteam.com',
      //   phoneNumber: '',
      //   licenseNumber: '995688590',
      //   title: 'Realtor',
      //   link: '',
      //   linkTitle: '',
      //   image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_sheri-luce.png`,
      // },
      {
        name: 'Susan Nesom',
        emailAddress: 'gameofhomesla@gmail.com',
        phoneNumber: '(225)939-5961',
        licenseNumber: '19670',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_susan-nesom.png`,
      },
      {
        name: 'Taylor Lott',
        emailAddress: 'lgameoomeso@gmaiicon',
        phoneNumber: '(225)235-2064',
        licenseNumber: '995687020',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_taylor-lott.png`,
      },
      {
        name: 'Tina O\'Neal',
        emailAddress: 'tinaoneal@att.net',
        phoneNumber: '(225)281-2696',
        licenseNumber: '119234',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_tina-oneal.png`,
      },
      {
        name: 'Andrew Tillman',
        emailAddress: 'andrew tillman@yahoo.com',
        phoneNumber: '(225)252-6805',
        licenseNumber: '995712798',
        title: 'Realtor',
        link: '',
        linkTitle: '',
        image: `${process.env.REACT_APP_API_URL}Attachments/AgentMembers/agent-member_andrew-tillman.png`,
      },
    ];
    setAgents(agentsData);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Our Agents"
        imageSource={BannerImage}
      />

      <CustomBlock className="content-container--padded">
        <ContentSection
          isTextOnly
          isFullWidthUnpadded
          title="Meet Our Experts"
          description="Navigating the real estate market can be complex, but not when you have our team of skilled agents at your side. With their combined years of experience and local knowledge, they're committed to offering you personalized service at every step."
        />
        <CustomBlock className="content-container--padded">
          <MemberListing
            members={agents}
            defaultImage={AgentImagePlaceholder}
          />
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default AgentsListingPage;