/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useState } from 'react';
import { connect } from 'react-redux';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContactForm from '../../../components/ContactForm';
import ContentSection from '../../../components/ContentSection';
// ASSETS - CONTACT FORM PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
import IntroSectionImage from '../../../assets/img/content-image_contact-page-intro.png';
// SERVICES AND HELPERS
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';
import * as emailService from '../../../services/management/emailService';

const ContactFormPage = (props) => {
  const { showAlert, history, cmsInfo } = props;
  const [isLoading, setIsLoading] = useState(false);
  const getDirectionsLink = 'https://www.google.com/maps/place/142+Del+Norte+Ave,+Denham+Springs,+LA+70726/@30.4706975,-90.9551617,17z/data=!3m1!4b1!4m6!3m5!1s0x8626bef0ef5cd50d:0x915fc5c888dc7ba6!8m2!3d30.4706975!4d-90.9551617!16s%2Fg%2F11c28v5b_f?entry=ttu';
  const formConfig = [
    { name: 'firstName', label: 'First Name', required: true, placeholder: 'e.g. John', max: 100 },
    { name: 'lastName', label: 'Last Name', required: true, placeholder: 'e.g. Smith', max: 100 },
    { name: 'email', label: 'Email Address', required: true, placeholder: 'e.g. johnsmith@example.com', max: 100, email: true },
    { name: 'phoneNumber', label: 'Phone Number', placeholder: 'e.g. (555) 555-5555', max: 50 },
    { name: 'message', label: 'Message', required: true, placeholder: 'Provide your message here...', textarea: true, cols: 12 },
  ];

  const submitForm = async (values) => {
    setIsLoading(true);
    await emailService.sendEmail(values).then(() => {
      showAlert({ text: 'Success! Your message has been received.', state: 'success' });
      history.push('/contact');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Contact"
        imageSource={BannerImage}
      />

      <CustomBlock className="content-container--padded">
        <ContentSection
          hasRoundCorners
          isFullWidth
          title="Connect With Us"
          description="Your dream property is just a message away. Contact us to make it a reality."
          imageSource={IntroSectionImage}
          primaryActionText="Get Directions"
          primaryActionOnClick={() => window.open(getDirectionsLink, '_blank')}
          primaryActionColor="primary--sbg"
        />

        <CustomBlock className="custom-content-section">
          <h1>Mandy Benton Realty Group</h1>
          <br />
          <h2>Location</h2>
          <p>{cmsInfo.streetAddress1}, {cmsInfo.streetAddress2}</p>
          <p>{cmsInfo.city}, {cmsInfo.state}, {cmsInfo.zipCode}</p>
          <br />
          <h2>Contact</h2>
          <p>Phone: {cmsInfo.phoneNumber}</p>
          <p>Email: {cmsInfo.emailAddress}</p>
          <br />
          {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours || cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
            <>
              <h2>Hours of Operation</h2>
              {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours) && (
                <CustomBlock className="mt-5 mb-5">
                  <p className="fw-600 primary-lighter--clr">Weekdays</p>
                  {cmsInfo.primaryWeekDayHours && (
                    <p>{cmsInfo.primaryWeekDayHours}</p>
                  )}
                  {cmsInfo.secondaryWeekDayHours && (
                    <p>{cmsInfo.secondaryWeekDayHours}</p>
                  )}
                </CustomBlock>
              )}
              {(cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
                <CustomBlock>
                  <p className="fw-600 primary-lighter--clr">Weekends</p>
                  {cmsInfo.primaryWeekEndHours && (
                    <p>{cmsInfo.primaryWeekEndHours}</p>
                  )}
                  {cmsInfo.secondaryWeekEndHours && (
                    <p>{cmsInfo.secondaryWeekEndHours}</p>
                  )}
                </CustomBlock>
              )}
            </>
          )}
        </CustomBlock>

        <CustomBlock className="content-section">
          <CustomBlock className="content-container--card-style--with-shadow">
            <ContactForm
              formConfig={formConfig}
              onSubmit={(values) => submitForm(values)}
              title="Your Dream Home Awaits – Get in Touch!"
              className="mb-20"
            />
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertToast.actions
})(ContactFormPage);