/* eslint-disable react/jsx-one-expression-per-line */
// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// ICONS
import { HiOutlineMail, HiOutlinePhone } from 'react-icons/hi';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';

const MemberListing = (props) => {
  const {
    members,
    defaultImage,
    className = '',
    renderCustomContent = null
  } = props;
  const menuToggleIconColor = 'white--clr';
  const menuToggleIconSize = 24;

  return (
    <CustomBlock hasNoContainer className={`listing-content ${className}`}>
      {members.map((member) => (
        <CustomBlock className="listing-item">
          <img
            src={member.image}
            title={`${member.name} (License #${member.licenseNumber})`}
            alt={member.name}
            className="listing-item__image"
            onError={(e) => {
              if (e.target.src !== defaultImage) {
                e.target.src = defaultImage;
              }
            }}
          />

          <h2 className="listing-item__title">{member.name}</h2>

          {member.title && (
            <em className="listing-item__position">{member.title}</em>
          )}

          {(member.emailAddress || member.phoneNumber) && (
            <CustomBlock className="agents-listing--icon-block mt-5">
              {member.emailAddress && (
                <a
                  href={`mailto:${member.emailAddress}`}
                  rel="noopener noreferrer"
                  title={member.emailAddress}
                  className="listing-item__email"
                >
                  <button className="social-icon primary-lighter--sbg circle">
                    <HiOutlineMail className={menuToggleIconColor} size={menuToggleIconSize} />
                  </button>
                </a>
              )}
              {member.phoneNumber && (
                <a
                  href={`tel:${member.phoneNumber}`}
                  rel="noopener noreferrer"
                  title={member.phoneNumber}
                  className="listing-item__email"
                >
                  <button className="social-icon primary-lighter--sbg circle">
                    <HiOutlinePhone className={menuToggleIconColor} size={menuToggleIconSize} />
                  </button>
                </a>
              )}
            </CustomBlock>
          )}

          {member.link && (
            <a
              href={member.link}
              className="listing-item__link"
              rel="noopener noreferrer"
              target="_blank"
            >
              {member.linkTitle}
            </a>
          )}

          {member.memberType === 'Team Member' && member.bioLink && (
            <CustomBlock className="text-block-action-container">
              <Button
                text="Read Bio"
                size="xs"
                type="button"
                className="primary--bg mt-20"
                to={member.bioLink}
              />
            </CustomBlock>
          )}

          {renderCustomContent && renderCustomContent(member)}
        </CustomBlock>
      ))}
    </CustomBlock>
  );
};

MemberListing.propTypes = {
  members: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    position: PropTypes.string,
    company: PropTypes.string,
    companyLink: PropTypes.string,
    imagePath: PropTypes.string
  })).isRequired,
  defaultImage: PropTypes.string,
  className: PropTypes.string,
  renderCustomContent: PropTypes.func
};

MemberListing.defaultProps = {
  defaultImage: '',
  className: '',
  renderCustomContent: null
};

export default MemberListing;