/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// COMPONENTS
import Slider from 'react-slick';
import MessengerCustomerChat from 'react-messenger-customer-chat';
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
import ContentSection from '../../../components/ContentSection';
import Banner from '../../../components/Banner';
import Button from '../../../components/Button';
// import MessengerWidget from '../../../components/MessengerWidget';
// ASSETS - APP LINKS
// import AppStoreBadge from '../../../assets/img/app-store-badge.svg';
// import GooglePlayBadge from '../../../assets/img/google-play-badge.svg';
// import FeaturedHomePlaceholder from '../../../assets/img/featured-home_placeholder.png';
import Logo from '../../../assets/img/logo.png';
import NeighborhoodIllustration from '../../../assets/img/content-illustration_neighborhood.svg';
// HELPERS
import constants from '../../../constants/constants';
// SERVICES
import * as featuredItemService from '../../../services/management/featuredItemService';

const HomePage = (props) => {
  const { dynamicPageModel, buttonAction, showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [featuredItems, setFeaturedItems] = useState([]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const navigateToLink = (link) => {
    window.open(link, '_blank');
  };

  useEffect(() => {
    fetchFeaturedItems();
  }, []);

  useEffect(() => {
    const rotatingBanners = dynamicPageModel.pageContentSectionBanners.find((x) => x.bannerId > 0);
    if (rotatingBanners) {
      setBanners(rotatingBanners.banner.bannerItems);
    }
  }, []);

  const fetchFeaturedItems = () => {
    setIsLoading(true);
    featuredItemService.getFeaturedItems().then((res) => {
      const activeItems = res.filter((item) => item.isActive);
      setFeaturedItems(activeItems);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const bannerSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 480,
        settings: { dots: false }
      }
    ]
  };

  const featuredSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 1800,
        settings: { slidesToShow: 3.5 }
      },
      {
        breakpoint: 1600,
        settings: { slidesToShow: 3 }
      },
      {
        breakpoint: 1350,
        settings: { slidesToShow: 2.5 }
      },
      {
        breakpoint: 1150,
        settings: { slidesToShow: 2, arrows: false }
      },
      {
        breakpoint: 1050,
        settings: { slidesToShow: 1.75, arrows: false }
      },
      {
        breakpoint: 900,
        settings: { slidesToShow: 1.5, arrows: false }
      },
      {
        breakpoint: 630,
        settings: { slidesToShow: 1.25, arrows: false }
      },
      {
        breakpoint: 568,
        settings: { slidesToShow: 1.25, arrows: false }
      },
      {
        breakpoint: 510,
        settings: { slidesToShow: 1, arrows: false }
      },
    ]
  };

  return (
    <>
      {(isLoading) && <Overlay hasLoader />}
      <CustomBlock>
        <CustomBlock className="banner-container">
          <Slider {...bannerSliderSettings}>
            {
              banners.map((banner) => (
                <Banner
                  isTitleUppercase
                  isReversed={banner.alignment === constants.CONTENT_SECTION.RightAlignment}
                  isCentered={banner.alignment === constants.CONTENT_SECTION.CenterAlignment}
                  title={banner.title}
                  imageSource={`${process.env.REACT_APP_API_URL}Attachments${banner.imagePath}`}
                  description={banner.description}
                  primaryActionText={banner.primaryButtonText}
                  primaryActionOnClick={() => {
                    buttonAction(banner.primaryButtonActionType, banner.primaryButtonLink);
                  }}
                  secondaryActionText={banner.secondaryButtonText}
                  secondaryActionOnClick={() => {
                    buttonAction(banner.secondaryButtonActionType, banner.secondaryButtonLink);
                  }}
                />
              ))
            }
          </Slider>
        </CustomBlock>
      </CustomBlock>

      <MessengerCustomerChat
        pageId="977581088938384"
        appId="your-app-id"
        greetingDialogDisplay="show"
        themeColor="#0084ff"
        loggedInGreeting="Hello! How can we assist you?"
        loggedOutGreeting="Hello! Please log in to start a chat."
      />

      {/* <CustomBlock className="">
        <MessengerWidget
          pageId="977581088938384"
          themeColor="#0084ff"
          loggedInGreeting="Hello! How can we assist you?"
          loggedOutGreeting="Hello! Please log in to start a chat."
        />
      </CustomBlock> */}

      {/* CONTENT SECTION - FIND HOME VALUE */}
      <CustomBlock className="content-section--find-home-value" style={{ backgroundImage: `url(${NeighborhoodIllustration})` }}>
        <Button
          type="button"
          text="Want to find the value of your home?"
          size="sm"
          to="/contact"
          className="secondary--bg"
        />
      </CustomBlock>

      <CustomBlock className="content-section--featured">
        <ContentSection
          isTextOnly
          hasRoundCorners
          title="Featured Listings"
          description="Explore our handpicked selection of featured homes, each a masterpiece of comfort and style."
        // primaryActionText="Let's Find Your Dream Home"
        // primaryActionTo="/contact"
        // className="pb-20"
        />

        <CustomBlock className="featured-listing">
          <Slider {...featuredSliderSettings}>
            {featuredItems.map((item) => (
              <CustomBlock
                className={`featured-item ${item.primaryButtonLink && 'has-link'}`}
                onClick={() => item.primaryButtonLink && navigateToLink(item.primaryButtonLink)}
              >
                <CustomBlock
                  className="image"
                  style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}Attachments${item.imageFilePath})` }}
                />
                <CustomBlock className="content">
                  <p className="title">{item.title}</p>
                  <p className="amount">{item.amount}</p>
                  <p className="address">
                    {item.addressLine1}<br />
                    {item.city}, {item.state} {item.zipcode}
                  </p>
                  <p className="mls">{item.propertyCode}</p>
                  <img
                    className="logo"
                    alt="Mandy Benton Logo"
                    src={Logo}
                  />
                </CustomBlock>
              </CustomBlock>
            ))}
          </Slider>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default HomePage;