// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
// ICONS
import { AiOutlineLoading } from 'react-icons/ai';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const Button = (props) => {
  const {
    text,
    size,
    type,
    style,
    to,
    onClick,
    className,
    isTextUppercase,
    isBadge,
    isLoading,
    isDisabled,
    buttonSubActions,
    isSubActionsVisible,
    iconLeft,
    iconRight
  } = props;

  const sizes = {
    lg: '--lg',
    md: '--md',
    sm: '--sm',
    xs: '--xs',
    xxs: '--xxs',
  };

  const history = useHistory();

  const navigateToPath = (path) => {
    history.push(path);
  };

  return (
    <>
      <button
        type={type}
        onClick={to ? () => navigateToPath(to) : onClick}
        className={`button${size && sizes[size]} ${className} ${isTextUppercase ? 'uppercase' : ''} ${isBadge ? 'badge' : ''}`}
        style={style}
        disabled={isDisabled || isLoading}
      >
        {iconLeft}
        {isLoading ? <AiOutlineLoading className="loading-indicator rotating mr-0" size={20} /> : text}
        {iconRight}
      </button>
      {
        buttonSubActions
        && (
          <CustomBlock className={`button-sub-actions-container ${isSubActionsVisible ? 'visible' : 'hidden'}`}>
            {buttonSubActions}
          </CustomBlock>
        )
      }
    </>
  );
};

Button.propTypes = {
  // MAIN PROPS
  text: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  to: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isTextUppercase: PropTypes.bool,
  isBadge: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,

  // SUB ACTIONS PROPS
  buttonSubActions: PropTypes.element,
  isSubActionsVisible: PropTypes.bool,

  // ICON PROPS
  iconLeft: PropTypes.element,
  iconRight: PropTypes.element
};

Button.defaultProps = {
  // MAIN PROPS
  text: '',
  size: '',
  type: 'button',
  style: {},
  to: null,
  onClick: () => { },
  className: '',
  isTextUppercase: false,
  isBadge: false,
  isLoading: false,
  isDisabled: false,

  // SUB ACTIONS PROPS
  buttonSubActions: null,
  isSubActionsVisible: false,

  // ICON PROPS
  iconLeft: null,
  iconRight: null
};

export default Button;