/* eslint-disable object-shorthand */
/* eslint-disable import/no-unresolved */
// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// COMPONENTS
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import ContentSection from './ContentSection';

const Banner = (props) => {
  const {
    bannerBackgroundColor,
    title,
    titleColor,
    description,
    descriptionColor,
    imageSource,
    titlePosition,
    isDynamicBanner,
    isTitleUppercase
  } = props;

  const textPositions = {
    left: 'left',
    center: 'center',
    right: 'right'
  };

  return (
    <CustomBlock
      className={`static-banner ${isDynamicBanner ? 'dynamic' : ''} ${titlePosition && textPositions[titlePosition]}`}
      style={{ backgroundImage: `url(${imageSource})`, backgroundColor: bannerBackgroundColor }}
    >
      <ContentSection
        isTitleUppercase={isTitleUppercase}
        {...props}
        isTextOnly
        hasRoundCorners
        title={title}
        description={description}
        imageSource={imageSource}
        titleColor={titleColor}
        descriptionColor={descriptionColor}
        primaryActionColor="white--sbg dark--clr"
        secondaryActionColor="secondary--bg"
      />
    </CustomBlock>
  );
};

Banner.propTypes = {
  // MAIN PROPS
  imageSource: PropTypes.number.isRequired,
  titlePosition: PropTypes.string,
  bannerBackgroundColor: PropTypes.string,

  // TITLE PROPS
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,

  // DESCRIPTION PROPS
  description: PropTypes.string,
  descriptionColor: PropTypes.string,

  // BOOLEAN PROPS
  isDynamicBanner: PropTypes.bool,
  isTitleUppercase: PropTypes.bool,
};

Banner.defaultProps = {
  // MAIN PROPS
  description: '',
  titlePosition: 'center',
  bannerBackgroundColor: '#213c63',

  // TITLE PROPS
  titleColor: 'white--clr',

  // DESCRIPTION PROPS
  descriptionColor: 'white--clr',

  // BOOLEAN PROPS
  isDynamicBanner: false,
  isTitleUppercase: false,
};

export default Banner;