/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import Image from '../../../../components/Image';
import Overlay from '../../../../components/Overlay';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import FixedActionsBar from '../../../../components/FixedActionsBar';
// HELPERS AND SERVICES
import * as helper from '../../../../helpers/helper';
import * as featuredItemService from '../../../../services/management/featuredItemService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';

const featuredItemModel = {
  id: 0,
  isActive: true,
  title: '',
  amount: '',
  propertyCode: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipcode: '',
  imageUrl: '',
  sortOrder: 0,
  primaryButtonLink: '',
};

const FeaturedItemManagementPage = (props) => {
  const { history, showAlert } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { featuredItemId } = useParams();
  const [featuredItem, setFeaturedItem] = useState(featuredItemModel);
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (featuredItemId) {
      fetchFeaturedItem();
    }
  }, []);

  const fetchFeaturedItem = () => {
    setIsLoading(true);
    featuredItemService.getFeaturedItemById(featuredItemId).then((res) => {
      setFeaturedItem(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: featuredItem,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      isActive: Yup.bool(),
      title: Yup.string().nullable().max(150),
      amount: Yup.string().nullable().max(150),
      imageFilePath: Yup.string().max(2000),
      addressLine1: Yup.string().required('Street Address is required').max(250),
      addressLine2: Yup.string().nullable().max(250),
      city: Yup.string().required('City is required').max(250),
      state: Yup.string().required('State is required').max(250),
      zipcode: Yup.string().max(10),
      propertyCode: Yup.string().nullable().max(20),
      primaryButtonLink: Yup.string().nullable().max(500),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (featuredItemId) {
        updateFeaturedItem(values);
      } else {
        createFeaturedItem(values);
      }
    },
  });

  const updateFeaturedItem = (model) => {
    setIsLoading(true);
    if (file.length > 0) {
      model = { ...model, image: file[0].file };
    } else {
      model = { ...model, imageUrl: featuredItem.imageFilePath };
    }
    featuredItemService.updateFeaturedItem(helper.convertJsonToFormData(model)).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/cms/management/featured-listing');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  const createFeaturedItem = (model) => {
    if (file.length === 0) {
      showAlert({ text: 'Please upload an image for the featured item.', state: 'warning' });
      return;
    }
    setIsLoading(true);
    featuredItemService.createFeaturedItem(helper.convertJsonToFormData(
      {
        ...model,
        image: file.length > 0 ? file[0].file : null
      }
    )).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      history.push('/cms/management/featured-listing');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'warning' });
    }).finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <FormBlock onSubmit={formik.submitForm}>

              {/* BASIC INFORMATION & LOCATION DETAILS */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow"
              >
                <ContentBlock>
                  <ContentHeader
                    title={featuredItemId ? 'Featured Item Details' : 'New Featured Item'}
                    headerSize="lg"
                  />
                </ContentBlock>

                <ContentBlock>
                  <ContentHeader
                    title="Basic Information"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <Section isFullWidth className="pl-0 pr-0">
                  <ContentBlock cols={2}>
                    <InputBlock
                      label="Title"
                      placeholder="e.g. River Pines Plantation"
                      errorMessage={formik.errors.title}
                      inputState={`${helper.getInputClasses(formik, 'title')}`}
                      {...formik.getFieldProps('title')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={2}>
                    <InputBlock
                      label="Listing Amount"
                      placeholder="e.g. $120,000"
                      errorMessage={formik.errors.amount}
                      inputState={`${helper.getInputClasses(formik, 'amount')}`}
                      {...formik.getFieldProps('amount')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={2}>
                    <InputBlock
                      label="MLS/Property Code"
                      placeholder="e.g. #2023001122"
                      errorMessage={formik.errors.propertyCode}
                      inputState={`${helper.getInputClasses(formik, 'propertyCode')}`}
                      {...formik.getFieldProps('propertyCode')}
                    />
                  </ContentBlock>
                </Section>

                <ContentBlock cols={6}>
                  <InputBlock
                    label="Item Link"
                    placeholder="e.g. /contact or https://google.com"
                    errorMessage={formik.errors.primaryButtonLink}
                    inputState={`${helper.getInputClasses(formik, 'primaryButtonLink')}`}
                    {...formik.getFieldProps('primaryButtonLink')}
                  />
                </ContentBlock>

                <ContentBlock className="mb-15">
                  <CheckboxBlock
                    label="Is Item Active?"
                    id="isActive"
                    {...formik.getFieldProps('isActive')}
                    isChecked={formik.values.isActive}
                  />
                </ContentBlock>

                {/* LOCATION */}
                <ContentBlock className="mt-30">
                  <ContentHeader
                    title="Location"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <Section isFullWidth className="pl-0 pr-0">
                  <ContentBlock cols={4}>
                    <InputBlock
                      isRequired
                      label="Street Address"
                      placeholder="e.g. 123 Main St"
                      errorMessage={formik.errors.addressLine1}
                      inputState={`${helper.getInputClasses(formik, 'addressLine1')}`}
                      {...formik.getFieldProps('addressLine1')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={2}>
                    <InputBlock
                      label="Apt/Unit/Suit"
                      placeholder="e.g. Apt. B"
                      errorMessage={formik.errors.addressLine2}
                      inputState={`${helper.getInputClasses(formik, 'addressLine2')}`}
                      {...formik.getFieldProps('addressLine2')}
                    />
                  </ContentBlock>
                </Section>

                <Section isFullWidth className="pl-0 pr-0">
                  <ContentBlock cols={2}>
                    <InputBlock
                      isRequired
                      label="City"
                      placeholder="e.g. Baton Rouge"
                      errorMessage={formik.errors.city}
                      inputState={`${helper.getInputClasses(formik, 'city')}`}
                      {...formik.getFieldProps('city')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={2}>
                    <InputBlock
                      isRequired
                      label="State"
                      placeholder="e.g. LA"
                      errorMessage={formik.errors.state}
                      inputState={`${helper.getInputClasses(formik, 'state')}`}
                      {...formik.getFieldProps('state')}
                    />
                  </ContentBlock>

                  <ContentBlock cols={2}>
                    <InputBlock
                      label="Zip/Post Code"
                      placeholder="e.g. 12345"
                      errorMessage={formik.errors.zipcode}
                      inputState={`${helper.getInputClasses(formik, 'zipcode')}`}
                      {...formik.getFieldProps('zipcode')}
                    />
                  </ContentBlock>
                </Section>
              </Section>

              {/* FEATURED ITEM IMAGE */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow mt-30 mb-80"
              >
                <ContentBlock className="mt-30">
                  <ContentHeader
                    title="Featured Listing Image"
                    subtitle="Upload an image for this featured item."
                    headerSize="md"
                    subtitleSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                  <ContentBlock cols={6}>
                    {!featuredItemId
                      ? (
                        <ContentBlock className="mt-15">
                          <FileUploadBlock onupdatefiles={setFile} />
                        </ContentBlock>
                      )
                      : (
                        <>
                          <CustomBlock className="flex-center pt-15 pb-30">
                            <Image
                              source={`${process.env.REACT_APP_API_URL}Attachments/${formik.values.imageFilePath}`}
                              className="featuredItem-image pl-10 pr-10"
                            />
                          </CustomBlock>
                          <FileUploadBlock onupdatefiles={setFile} labelIdle="Replace image" />
                        </>
                      )}
                  </ContentBlock>
                </ContentBlock>
              </Section>

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={featuredItemId > 0 ? 'Save Changes' : 'Create Featured Item'}
                primaryActionColor="primary--bg"
                primaryActionOnClick={formik.handleSubmit}
                secondaryActionText="Cancel"
                secondaryActionTo="/cms/management/featured-listing"
                optionalActionText={featuredItemId > 0 ? 'Return to Listing' : ''}
                optionalActionTo="/cms/management/featured-listing"
              />
            </FormBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...alert.actions
  }
)(FeaturedItemManagementPage);