/* eslint-disable react/self-closing-comp */
// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
// COMPONENTS
// ICONS
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Overlay from './Overlay';

const DrawerNavigation = (props) => {
  const {
    children,
    footerItems,
    backgroundColor,
    onClick,
    isOpen,
    hasOverlay,
    willCloseOnMaskPress,
    hasShadow,
  } = props;

  return (
    <>
      {
        (isOpen && hasOverlay)
        && (
          <Overlay
            onClick={willCloseOnMaskPress && onClick}
            overlayDarkness="dark"
          />
        )
      }
      <CustomBlock className={`drawer-navigation ${isOpen ? 'open' : ''} ${backgroundColor} ${hasShadow ? 'with-shadow' : ''}`}>
        <CustomBlock className="drawer-header">

        </CustomBlock>

        <CustomBlock className="drawer-menu-items-container">
          {children}
        </CustomBlock>

        <CustomBlock className="drawer-footer">
          {footerItems}
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

DrawerNavigation.propTypes = {
  // MAIN PROPS
  children: PropTypes.any,
  footerItems: PropTypes.any,
  backgroundColor: PropTypes.string,

  // FUNCTION PROPS
  onClick: PropTypes.func,

  // BOOLEAN PROPS
  isOpen: PropTypes.bool,
  hasOverlay: PropTypes.bool,
  willCloseOnMaskPress: PropTypes.bool,
  hasShadow: PropTypes.bool,
};

DrawerNavigation.defaultProps = {
  // MAIN PROPS
  children: null,
  footerItems: null,
  backgroundColor: 'white--sbg',

  // FUNCTION PROPS
  onClick: null,

  // BOOLEAN PROPS
  isOpen: false,
  hasOverlay: false,
  willCloseOnMaskPress: false,
  hasShadow: false,
};

export default DrawerNavigation;