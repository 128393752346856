/* eslint-disable max-len */
import axiosInstance from '../apiService';
import constants from '../../constants/constants';

const ENDPOINTORIGIN = 'FeaturedItem/';

const getFeaturedItems = async () => axiosInstance.get(`${ENDPOINTORIGIN}GetFeaturedItems`, constants.REQUIRE_INTERCEPTORS);

const getFeaturedItemById = async (featuredItemId) => axiosInstance.get(`${ENDPOINTORIGIN}GetFeaturedItemById?featuredItemId=${featuredItemId}`, constants.REQUIRE_INTERCEPTORS);

const createFeaturedItem = async (model) => axiosInstance.post(`${ENDPOINTORIGIN}CreateFeaturedItem`, model, constants.REQUIRE_INTERCEPTORS);

const updateFeaturedItem = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}UpdateFeaturedItem`, model, constants.REQUIRE_INTERCEPTORS);

const deleteFeaturedItem = async (featuredItemId) => axiosInstance.delete(`${ENDPOINTORIGIN}DeleteFeaturedItem?featuredItemId=${featuredItemId}`, constants.REQUIRE_INTERCEPTORS);

const sortFeaturedItems = async (model) => axiosInstance.put(`${ENDPOINTORIGIN}SortFeaturedItems`, model, constants.REQUIRE_INTERCEPTORS);

export {
  getFeaturedItems,
  getFeaturedItemById,
  createFeaturedItem,
  updateFeaturedItem,
  deleteFeaturedItem,
  sortFeaturedItems
};