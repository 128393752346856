// DEPENDENCIES
import React from 'react';
import PropTypes from 'prop-types';
import 'flatpickr/dist/themes/material_green.css';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import InputMask from 'react-input-mask';
import Flatpickr from 'react-flatpickr';
// ICONS
import { AiOutlineLoading } from 'react-icons/ai';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';

const InputBlock = (props) => {
  const {
    id,
    containerClassName,
    label,
    inputLabel,
    inputState,
    errorMessage,
    successMessage,
    warningMessage,
    type,
    className,
    labelColor,
    backgroundColor,
    placeholder,
    mask,
    to,
    iconLeft,
    iconRight,
    inputRightButtonText,
    inputRightButtonBackgroundColor,
    inputRightButtonTextColor,
    iconLeftOnClick,
    iconRightOnClick,
    inputRightButtonOnClick,
    isRequired,
    isLoading,
    isLabelLoading,
    isAutoFocus,
    isDisabled,
    hasBorder,
  } = props;
  const errorMessageSuffix = 'is required';
  const inputStates = {
    error: 'error',
    warning: 'warning',
    success: 'success',
    disabled: 'disabled'
  };

  const marginTop = () => {
    if (inputLabel) {
      return 25;
    } if (label) {
      return 35;
    }
    return 10;
  };

  const history = useHistory();

  const navigateToPath = (path) => {
    history.push(path);
  };

  return (
    <CustomBlock className={`input-block-container ${containerClassName}`}>
      {
        inputLabel !== ''
        && (
          <span className={`static-floating-label ${inputState}`}>
            {inputLabel}
            {isRequired && <span className="danger--clr fw-600">*</span>}
            {
              isLabelLoading
              && (
                <AiOutlineLoading
                  tabIndex="-1"
                  className="loading-indicator rotating success--clr ml-5"
                  size={16}
                  style={{ marginTop: -5 }}
                />
              )
            }
          </span>
        )
      }
      {
        label !== ''
        && (
          <span className={`static-label ${inputState} ${labelColor} `}>
            {label}
            {isRequired && <span className="danger--clr fw-600">*</span>}
            {
              isLabelLoading
              && (
                <AiOutlineLoading
                  tabIndex="-1"
                  className="loading-indicator rotating success--clr ml-10"
                  size={18}
                />
              )
            }
          </span>
        )
      }
      <CustomBlock>
        {
          iconLeft
          && (
            <CustomBlock className="icon-left-container" style={{ marginTop: marginTop() }}>
              <button
                type="button"
                tabIndex="-1"
                onClick={iconLeftOnClick}
              >
                {iconLeft}
              </button>
            </CustomBlock>
          )
        }

        {
          type === 'date'
            ? (
              <Flatpickr
                {...props}
                id={id}
                placeholder={placeholder}
                className={`input-block ${className} ${backgroundColor} ${inputState && inputStates[inputState]} ${hasBorder ? 'bordered' : ''} ${!inputLabel ? 'padded' : ''}`}
                type={type}
                autoFocus={isAutoFocus}
                disabled={!!(isDisabled || inputState === 'disabled')}
                style={{ paddingLeft: iconLeft ? 50 : 15 }}
              />
            )
            : (
              <InputMask
                {...props}
                id={id}
                mask={mask}
                placeholder={placeholder}
                className={`input-block ${className} ${backgroundColor} ${inputState ? inputStates[inputState] : ''} ${hasBorder ? 'bordered' : ''} ${!inputLabel ? 'padded' : ''}`}
                type={type}
                autoFocus={isAutoFocus || false}
                disabled={!!(isDisabled || inputState === 'disabled')}
                style={{ paddingLeft: iconLeft ? 50 : 15 }}
              />
            )
        }

        {
          (isLoading || iconRight || inputRightButtonText)
          && (
            <CustomBlock className="icon-right-container" style={{ marginTop: marginTop(), width: inputRightButtonText ? 'unset%' : 45 }}>
              {
                isLoading
                  ? (
                    <AiOutlineLoading
                      tabIndex="-1"
                      className="loading-indicator rotating success--clr"
                      size={22}
                    />
                  )
                  : (
                    <button
                      type="button"
                      tabIndex="-1"
                      onClick={iconRightOnClick}
                    >
                      {iconRight}
                    </button>
                  )
              }
              {
                inputRightButtonText
                && (
                  <button
                    type="button"
                    tabIndex="-1"
                    onClick={to ? () => navigateToPath(to) : inputRightButtonOnClick}
                    className={`input-right-button ${inputRightButtonBackgroundColor} ${inputRightButtonTextColor}`}
                  >
                    {inputRightButtonText}
                  </button>
                )
              }
            </CustomBlock>
          )
        }
      </CustomBlock>

      {
        inputState === 'error'
        && (
          <span className={`input-state-text error-message ${!inputLabel || label ? 'unpadded' : ''}`}>
            {errorMessage || (inputLabel || label ? (inputLabel || `${label} ${errorMessageSuffix}`) : 'Error message undefined')}
          </span>
        )
      }
      {
        inputState === 'warning'
        && (
          <span className={`input-state-text warning-message ${!inputLabel || label ? 'unpadded' : ''}`}>
            {warningMessage}
          </span>
        )
      }
      {
        inputState === 'success'
        && (
          <span className={`input-state-text success-message ${!inputLabel || label ? 'unpadded' : ''}`}>
            {successMessage}
          </span>
        )
      }
    </CustomBlock>
  );
};

InputBlock.propTypes = {
  // CONTAINER PROPS
  containerClassName: PropTypes.string,
  id: PropTypes.string,

  // LABEL PROPS
  label: PropTypes.string,
  inputLabel: PropTypes.string,

  // STATE PROPS
  inputState: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  warningMessage: PropTypes.string,

  // INPUT PROPS
  type: PropTypes.string,
  className: PropTypes.string,
  labelColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.string,

  // NAVIGATION PROPS
  to: PropTypes.string,

  // ITEMS AND ICON PROPS
  iconLeft: PropTypes.any,
  iconRight: PropTypes.any,
  inputRightButtonText: PropTypes.string,
  inputRightButtonBackgroundColor: PropTypes.string,
  inputRightButtonTextColor: PropTypes.string,

  // FUNCTION PROPS
  iconLeftOnClick: PropTypes.func,
  iconRightOnClick: PropTypes.func,
  inputRightButtonOnClick: PropTypes.func,

  // BOOLEAN PROPS
  isRequired: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLabelLoading: PropTypes.bool,
  isAutoFocus: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasBorder: PropTypes.bool,
};

InputBlock.defaultProps = {
  // CONTAINER PROPS
  containerClassName: '',
  id: '',

  // LABEL PROPS
  label: '',
  inputLabel: '',

  // STATE PROPS
  inputState: '',
  errorMessage: '',
  successMessage: '',
  warningMessage: '',

  // INPUT PROPS
  type: 'text',
  className: '',
  labelColor: 'dark--clr',
  backgroundColor: 'light--sbg',
  placeholder: '',
  mask: '',

  // NAVIGATION PROPS
  to: '',

  // ITEMS AND ICON PROPS
  iconLeft: null,
  iconRight: null,
  inputRightButtonText: '',
  inputRightButtonBackgroundColor: '',
  inputRightButtonTextColor: 'grey--clr',

  // FUNCTION PROPS
  iconLeftOnClick: () => { },
  iconRightOnClick: () => { },
  inputRightButtonOnClick: () => { },

  // BOOLEAN PROPS
  isRequired: false,
  isLoading: false,
  isLabelLoading: false,
  isAutoFocus: false,
  isDisabled: false,
  hasBorder: false,
};

export default InputBlock;