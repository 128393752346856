/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContentSection from '../../../components/ContentSection';
import Image from '../../../components/Image';
import MemberListing from '../../../components/MemberListing';
import TextHeader from '../../../components/TextHeader';
// ASSETS - PROPERTY SEARCH PAGE
import BannerImage from '../../../assets/img/banner-image_default.png';
import GBRMap from '../../../assets/img/content-image_gbr-map.png';
// SERVICES AND HELPERS

const PropertySearchPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeIframe, setActiveIframe] = useState(null);

  const MLSSearchURL = 'https://mlsbox.paragonrels.com/idx/idx.aspx?RMLS_SESSION_GUID=%7b1c588142-f2dd-4af1-aabd-2533af62362f%7d&MLS=MLSBOX&SUBSCRIBER=b7eebed1-f916-41c8-9964-f1c399745e3a&&MLSSearch=1';
  const OtherCriteriaSearchURL = 'https://mlsbox.paragonrels.com/idx/idx.aspx?RMLS_SESSION_GUID=%7ba51b67fd-c039-4a99-8ca4-869acde23c4d%7d&MLS=MLSBOX&SUBSCRIBER=b7eebed1-f916-41c8-9964-f1c399745e3a&';

  useEffect(() => {

  }, []);

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Search Properties"
        imageSource={BannerImage}
      />

      <CustomBlock className="content-container--padded">

        <ContentSection
          isTextOnly
          hasRoundCorners
          isFullWidthUnpadded
          title="Find Your Dream Property"
          description="Explore our extensive database of properties to discover your perfect home or investment opportunity."
          primaryActionText="Search by MLS # or Address"
          primaryActionColor="primary--sbg"
          primaryActionOnClick={() => setActiveIframe('mls')}
          secondaryActionText="Search by Other Criteria"
          secondaryActionColor="secondary--sbg"
          secondaryActionOnClick={() => setActiveIframe('other')}
        />

        {activeIframe === 'mls' && (
          <CustomBlock className="content-section">
            <iframe
              src={MLSSearchURL}
              title="MLS Search"
              width="100%"
              height="350px"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            >
            </iframe>
          </CustomBlock>
        )}

        {activeIframe === 'other' && (
          <CustomBlock className="content-section">
            <iframe
              src={OtherCriteriaSearchURL}
              title="Other Criteria Search"
              width="100%"
              height="1000px"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            >
            </iframe>
          </CustomBlock>
        )}

        <CustomBlock className="content-section" style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <TextHeader
            subtitle="Greater Baton Rouge MLS Area Map"
            subtitleSize="lg"
            subtitleClassName="primary--clr"
          />
          <Image
            source={GBRMap}
          />
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default PropertySearchPage;