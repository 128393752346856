/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
// ICONS
import { BsFillHouseAddFill } from 'react-icons/bs';
// CUSTOM COMPONENTS
import Overlay from '../../../../components/Overlay';
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import ModalBlock from '../../../../components/ModalBlock';
// SERVICES AND HELPERS
import * as helper from '../../../../helpers/helper';
import * as featuredItemService from '../../../../services/management/featuredItemService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as authRedux from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const FeaturedItemListingPage = (props) => {
  const {
    history,
    showAlert,
    auth,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [featuredItems, setFeaturedItems] = useState([]);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [sortList, setSortList] = useState([]);
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    fetchFeaturedItems();
  }, []);

  useEffect(() => {
    setSortList(featuredItems.sort((a, b) => a - b));
  }, [featuredItems]);

  const fetchFeaturedItems = () => {
    setIsLoading(true);
    featuredItemService.getFeaturedItems().then((res) => {
      setFeaturedItems(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const sortFeaturedItems = () => {
    setIsLoading(true);
    featuredItemService.sortFeaturedItems(sortList).then((res) => {
      fetchFeaturedItems();
      showAlert({ text: res.message, state: 'success' });
      setIsSortModalOpen(false);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const deleteFeaturedItems = (featuredItemId) => {
    setIsLoading(true);
    featuredItemService.deleteFeaturedItem(featuredItemId).then((res) => {
      fetchFeaturedItems();
      showAlert({ text: res.message, state: 'success' });
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const tempArr = helper.reOrderDnd(
      sortList,
      result.source.index,
      result.destination.index
    );

    setSortList(tempArr);
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
    jumpToPage: true,
    textLabels: { pagination: { jumpToPage: 'Page No:' } }
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Edit"
              className="primary--bg ml-0"
              size="xxs"
              to={`/cms/management/featured/edit/${featuredItems[dataIndex].id}`}
            />
            <Button
              text="Remove"
              className="danger--bg"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove Featured Item',
                  text: 'Are you sure you want to remove this featured item?',
                  rightBtnText: 'Confirm',
                  btnAction: () => {
                    deleteFeaturedItems(featuredItems[dataIndex].id);
                  }
                });
              }}
            />
          </CustomBlock>
        )
      }
    },
    {
      name: 'imageFilePath',
      label: 'Property Image',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const imageUrl = featuredItems[dataIndex].imageFilePath;
          return (
            <CustomBlock
              className="image"
              style={{
                background: `url(${process.env.REACT_APP_API_URL}Attachments${imageUrl})  no-repeat center/120%`,
                width: '140px',
                height: '70px',
                borderRadius: '4px'
              }}
            />
          );
        }
      }
    },
    {
      name: 'title',
      label: 'Title',
      options: columnOptions,
    },
    {
      name: 'addressLine1',
      label: 'Address',
      options: columnOptions,
    },
    {
      name: 'propertyCode',
      label: 'MLS/Property Code',
      options: columnOptions,
    },
    {
      name: 'isActive',
      label: 'Is Active?',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => (
          <p>{featuredItems[dataIndex].isActive ? 'Yes' : 'No'}</p>
        )
      }
    },
  ];

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Featured Listing"
                headerSize="lg"
                primaryButtonText={auth.isAdmin ? 'Add Listing Item' : ''}
                primaryButtonIconLeft={<BsFillHouseAddFill className={iconColor} size={iconSize} />}
                primaryButtonTo="/cms/management/featured"
                primaryButtonOnClick={() => {
                  history.push('/cms/management/featured');
                }}
                secondaryButtonText="Sort Items"
                secondaryButtonOnClick={() => {
                  setIsSortModalOpen(true);
                }}
              />

              <CustomBlock>
                <MUIDataTable
                  data={featuredItems}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>

      {/* SORT MODAL */}
      <ModalBlock
        hasCloseAction
        isVisible={isSortModalOpen}
        size="md"
        contentHeader="Sort Items"
        primaryModalActionText="Save Changes"
        primaryModalActionOnClick={sortFeaturedItems}
        onHide={() => {
          setIsSortModalOpen(false);
        }}
      >
        <Section hasNoContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(droppableProvided) => (
                <div ref={droppableProvided.innerRef}>
                  {sortList.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(draggableProvided) => (
                        <div
                          key={index}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          {...draggableProvided.dragHandleProps}
                          className="content-container--card-style light-grey--sbg pt-15 pb-15 pl-20 pr-20 mb-15"
                        >
                          <CustomBlock style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <CustomBlock
                              className="image"
                              style={{
                                background: `url(${process.env.REACT_APP_API_URL}Attachments${item.imageFilePath})  no-repeat center/120%`,
                                width: '100px',
                                height: '50px',
                                borderRadius: '4px'
                              }}
                            />
                            <p className="main-font fw-500">{item.addressLine1}</p>
                          </CustomBlock>
                        </div>

                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Section>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });
export default connect(
  mapStateFromProps,
  {
    ...authRedux.actions,
    ...alert.actions,
    ...confirmModal.actions
  }
)(FeaturedItemListingPage);